
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    RecordingsWidget,
  },
  layout: "widget",
  async asyncData({ query }) {
    useAccountStore().isRecordingWidget = true

    return {
      cameraId: query.camera,
      delay: Number.parseInt(query.d || "0"),
      apiId: query.api_id,
      apiKey: query.api_key,
      darkMode: query.dark_mode === "true",
      embedded: query.embedded === "true",
      sidebar: query.sidebar === "true",
    }
  },
}
